body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  overflow-x: hidden;
}
.MuiFormControlLabel-label {
  font-size: 14px !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.map-container {
  height: 300px !important;
  width: 100%;
  overflow: hidden;
}
.activity-map-container {
  height: 100% !important;
  width: 100%;
  overflow: hidden;
}
canvas.mapboxgl-canvas {
  border-radius: 10px;
  width: 100% !important;
}
.mapboxgl-control-container {
  display: none;
}

@font-face {
  font-family: 'Urbanist';
  src: local('Urbanist'), url(./assets/fonts/Urbanist.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(./assets/fonts/Montserrat.ttf) format('truetype');
}

@font-face {
  font-family: 'UrbanistBold';
  font-weight: 900;
  src: local('UrbanistBold'),
    url(./assets/fonts/Urbanist-Bold.ttf) format('truetype');
}

.textEllipsis {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  background: #fff;
}
.sliderItem {
  margin-bottom: 20;
  display: flex;
  flex-direction: column;
}
.cross {
  padding: 2;
  position: relative;
  display: inline-block;
}
.cross::before,
.cross::after {
  content: '';
  width: 100%;
  position: absolute;
  right: 0;
  top: 50%;
}
.cross::before {
  border-bottom: 3px solid #cd0438;
  -webkit-transform: skewY(-10deg);
  transform: skewY(-10deg);
}
.cross::after {
  border-bottom: 3px solid #cd0438;
  -webkit-transform: skewY(10deg);
  transform: skewY(10deg);
}
a:link {
  color: #cd0438;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: #df466e;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: #df466e;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: #df466e;
  background-color: transparent;
  text-decoration: underline;
}
* {
  box-sizing: border-box;
}

.img-wrapper {
  overflow: hidden;
}
img.hover-zoom:hover {
  transform: scale(2);
}

ul {
  list-style-type: square;
}
ul > li > ul {
  list-style-type: circle;
}
ul > li > ul > li > ul {
  list-style-type: square;
}
ol li {
  font-family: Arial;
}
[data-custom-class='body'],
[data-custom-class='body'] * {
  background: transparent !important;
}
[data-custom-class='title'],
[data-custom-class='title'] * {
  font-family: Arial !important;
  font-size: 26px !important;
  color: #000000 !important;
}
[data-custom-class='subtitle'],
[data-custom-class='subtitle'] * {
  font-family: Arial !important;
  color: #595959 !important;
  font-size: 14px !important;
}
[data-custom-class='heading_1'],
[data-custom-class='heading_1'] * {
  font-family: Arial !important;
  font-size: 19px !important;
  color: #000000 !important;
}
[data-custom-class='heading_2'],
[data-custom-class='heading_2'] * {
  font-family: Arial !important;
  font-size: 17px !important;
  color: #000000 !important;
}
[data-custom-class='body_text'],
[data-custom-class='body_text'] * {
  color: #595959 !important;
  font-size: 14px !important;
  font-family: Arial !important;
}
[data-custom-class='link'],
[data-custom-class='link'] * {
  color: #3030f1 !important;
  font-size: 14px !important;
  font-family: Arial !important;
  word-break: break-word !important;
}
.alertWindow {
  display: flex;
  align-items: center;
  overflow: hidden !important;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

input {
  border: none;
}

.MuiOutlinedInput-notchedOutline {
  border-color: transparent;
  border-bottom: rgba(0, 0, 0, 0.23);
}

.player-wrapper {
  position: relative;
  width: '100vw' !important;
  height: '100vh';
  overflow: hidden;
}

.player-wrapper video {
  min-width: 100vw !important;
  object-fit: cover;
  filter: blur(4px);
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.floating {
  animation: float 3s ease-in-out infinite;
}

.VideoInput {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
}

.VideoInput_input {
  display: none;
}

.VideoInput_video {
  display: block;
  margin: 0;
}

.VideoInput_footer {
  background: #eee;
  width: 100%;
  min-height: 40px;
  line-height: 40px;
  text-align: center;
}
